<template>
  <b-notification
    v-if="showWarning"
    class="has-padding-75 is-size-7 has-text-warning"
    :closable="false"
    type="is-black"
  >
    <p>
      <i
        class="fas has-margin-right-25"
        :class="{
          'fa-eye-slash': !isProcessing,
          'fa-spinner fa-pulse': isProcessing
        }"
      ></i>
      This task is <strong>NOT VISIBLE</strong> to any clients.
      <template v-if="task.siteId">
        <u class="has-cursor-pointer" @click="addOwnerAsParticipant">
          <strong>Click here</strong>
        </u>
        to add the site owner as a participant.
      </template>
    </p>
  </b-notification>
</template>

<script>
export default {
  name: "NoClientWarning",
  inject: ["$taskProvider"],
  props: {
    task: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      isProcessing: false
    };
  },
  computed: {
    $task() {
      return this.$taskProvider();
    },

    /**
     * @name showWarning
     * @desc Determine whether or not show the warning message by diffing the
     * agents and participants array. If the resulting array is empty, the task
     * in question will not be visible to ANY clients.
     */

    showWarning() {
      const agents = this.$_.get(this.task, "agents", []);
      const participants = this.$_.get(this.task, "participants", []);
      return !this.$_.difference(participants, agents).length;
    }
  },
  methods: {
    addOwnerAsParticipant() {
      if (this.isProcessing) return;
      this.isProcessing = true;
      this.$store
        .dispatch("tasks/updateParticipants", {
          taskId: this.task._id,
          users: this.$_.union(this.task.participants, [
            this.$_.get(this.$task, "site.authorId")
          ])
        })
        .then(() => {
          this.$toast.open({
            message: "Participant added"
          });
        })
        .catch(error => {
          this.$toast.open({
            message: `${error.message}`,
            type: "is-danger"
          });
        })
        .finally(() => {
          this.isProcessing = false;
        });
    }
  }
};
</script>
